import axios from '@/axios'
import store from '@/store'
import { startAuthentication, startRegistration } from '@simplewebauthn/browser'
import { jwtDecode } from 'jwt-decode'

/**
 * Logs in the user using the provided credentials and authentication method.
 *
 * @param {Object} credentials - User authentication credentials.
 * @param {string} credentials.authMethod - Authentication method (e.g., "password+otp").
 * @param {string} credentials.email - User's email.
 * @param {string} credentials.password - User's password.
 * @param {string} credentials.otp - One-time password (if applicable).
 * @param {string} method - The authentication method to be included in the request.
 * @returns {Promise<string>} Access token upon successful login.
 * @throws {Error} If authentication fails or a network error occurs.
 */
export async function login(requestData) {
  try {
    const response = await axios.post('/api/auth/login', requestData, {
      authRequired: false,
    })

    if (response.data.success) {
      // Dispatch the 'login' action to store tokens in Vuex state
      store.dispatch('login', {
        accessToken: response.data.result.access_token,
        user: response.data.result.user,
      })
    }
    return {
      success: response.data.success,
      message: response.data.message,
      result: null,
    }
  } catch (error) {
    return {
      success: false,
      message: 'Authentication error: ' + error,
      result: null,
    }
  }
}

/**
 * Retrieves user information by calling the /api/auth/user endpoint.
 *
 * @returns {Promise<Object|null>} User information or null if not authenticated.
 * @throws {Error} If there's a network or other error.
 */
export async function getUserInfo() {
  // Get the user info
  const response = await axios.get('/api/auth/user', {
    authRequired: true,
  })

  const authToken = store.getters.getAccessToken

  const decodedToken = jwtDecode(authToken)

  store.commit('setPermissions', decodedToken.permissions || [])

  if (response.data.success) {
    return { success: true, message: null, result: response.data.result }
  } else {
    return {
      success: false,
      message: 'User not found or error',
      result: null,
    }
  }
}

/**
 * Refresh the access token by sending a request to the backend.
 * @returns {Promise<Object>} - Promise that resolves to an object with result information.
 */
export async function refreshAccessToken() {
  try {
    // Step 1: Call the refresh endpoint on the backend
    const response = await axios.post(
      '/api/auth/refresh',
      {},
      {
        authRequired: false,
      },
    )

    if (response.data.success) {
      // Step 2: If the refresh is successful, dispatch the 'login' action to store the new access token in Vuex state
      store.dispatch('login', {
        accessToken: response.data.access_token,
      })

      // Step 3: Return an object indicating success with the new access token
      return {
        success: true,
        message: null,
        result: response.data.access_token,
      }
    } else {
      // Step 4: If the refresh fails or user is not authenticated, return an object indicating failure
      return { success: false, message: 'Refresh failed', result: null }
    }
  } catch (error) {
    // Handle network or other errors during the token refresh and return an error object
    return { success: false, message: 'Refresh error', result: null }
  }
}

/**
 * Perform WebAuthn registration for the current user.
 *
 * @param {Object} params - Registration parameters for WebAuthn.
 * @returns {Promise<Object>} - Promise that resolves to an object with result information.
 */
export async function webAuthnRegister(params) {
  try {
    let generateRegistrationOptionsResponse

    // Step 1: Call the backend to generate WebAuthn registration options
    generateRegistrationOptionsResponse = await axios.post(
      '/api/auth/webauthn/generateRegistrationOptions',
      params,
      {
        authRequired: true,
      },
    )
    const registrationOptions = generateRegistrationOptionsResponse.data.result

    let authenticatorResponse

    // Step 2: Pass the options to the authenticator and wait for a response
    authenticatorResponse = await startRegistration(registrationOptions)

    // Step 3: Call the backend to verify the WebAuthn registration
    await axios.post(
      '/api/auth/webauthn/verifyRegistration',
      authenticatorResponse,
      {
        authRequired: true,
      },
    )

    // Step 4: Return a success message
    return {
      success: true,
      message: 'Registered',
      result: null,
    }
  } catch (error) {
    // Handle errors during the WebAuthn registration process
    let message = 'Error'

    if (error.name === 'NotAllowedError') {
      message = 'NotAllowedError'
    } else if (
      error.name === 'InvalidStateError' &&
      error.message === 'The authenticator was previously registered'
    ) {
      message = 'AlreadyRegisteredError'
    }

    return { success: false, message: message, result: null }
  }
}

/**
 * Perform a WebAuthn login by sending a series of requests to the backend.
 * @param {string} email - The user's email for WebAuthn login.
 * @returns {string} The access token on successful login.
 * @throws {Error} If authentication fails.
 */
export async function webAuthnLogin(email) {
  try {
    //  Generate authentication options by calling the backend endpoint
    const generateAuthenticationOptionsResponse = await axios.post(
      '/api/auth/webauthn/generateAuthenticationOptions',
      { email: email },
      {
        authRequired: false,
      },
    )
    const authenticationOptions =
      generateAuthenticationOptionsResponse.data.result

    //  Start authentication with the options and wait for a response
    const authenticatorResponse = await startAuthentication(
      authenticationOptions,
    )

    //  Verify the authentication response with the backend
    const responseVerification = await axios.post(
      '/api/auth/webauthn/verifyAuthentication',
      { response: authenticatorResponse },
      {
        authRequired: false,
      },
    )
    const data = responseVerification.data

    if (data.success) {
      // If authentication is successful, dispatch the 'login' action to store tokens in Vuex state
      store.dispatch('login', {
        accessToken: data.access_token,
      })

      return { success: true, message: null, result: data.access_token }
    } else {
      // If authentication fails, throw an error
      return { success: false, message: null, result: null }
    }
  } catch (error) {
    return { success: false, message: error, result: null }
  }
}

/**
 * Delete a WebAuthn token by sending a delete request to the backend.
 * @param {Object} token - The WebAuthn token to delete.
 * @returns {Object} An object containing the result of the deletion.
 */
export async function webAuthnTokenDelete(token) {
  try {
    // Make a DELETE request to delete the WebAuthn token
    const response = await axios.delete(
      `/api/auth/webauthn/token/${token.id}`,
      {
        authRequired: true,
      },
    )

    const data = response.data

    if (data.success) {
      // If the deletion is successful, return an object with 'ok' set to true
      return { success: true, message: null, result: null }
    } else {
      // If the deletion fails, return an object with 'ok' set to false
      return { success: false, message: null, result: null }
    }
  } catch (error) {
    // Handle any errors that occur during the request silently by doing nothing
    return { success: false, message: error, result: null }
  }
}

/**
 * Modify a WebAuthn token by sending a request to the backend.
 * @param {Object} token - The WebAuthn token to modify.
 * @returns {Object} An object containing the result of the modification.
 */
export async function webAuthnTokenModify(token) {
  try {
    // Make a PUT request to modify the WebAuthn token
    const response = await axios.put(
      `/api/auth/webauthn/token/${token.id}`,
      {
        token,
      },
      {
        authRequired: true,
      },
    )

    const data = response.data

    if (data.success) {
      // If the modification is successful, return an object with 'ok' set to true
      return { success: true, message: null, result: null }
    } else {
      // If the modification fails, return an object with 'ok' set to false
      return { success: false, message: null, result: null }
    }
  } catch (error) {
    // Handle any errors that occur during the request silently by doing nothing
    return { success: false, message: error, result: null }
  }
}

/**
 * Function to modify an authentication method's status by sending a request to the backend.
 * @param {string} method - The authentication method to modify.
 * @param {string} status - The new status to set for the authentication method.
 * @returns {Promise} A promise that resolves with the updated authentication method data.
 */
export async function modifyAuthMethod(method, status) {
  try {
    // Call the endpoint on the backend to modify the authentication method
    const response = await axios.put(
      `/api/auth/method/${method}`,
      { status: status },
      {
        authRequired: true,
      },
    )

    const data = response.data

    return data
  } catch (error) {
    // Handle any errors that occur during the request silently
    return null
  }
}

/**
 * Function to get authentication methods by sending a request to the backend.
 * @returns {Promise} A promise that resolves with the authentication methods data.
 */
export async function getAuthMethods() {
  try {
    // Call the endpoint on the backend to retrieve authentication methods
    const response = await axios.get('/api/auth/method/', {
      authRequired: true,
    })
    const data = response.data

    return data
  } catch (error) {
    // Handle any errors that occur during the request silently
    return null
  }
}

/**
 * Logout function that logs the user out by sending a logout request to the backend.
 * @param {Object} to - The route the user is navigating to.
 * @param {Object} from - The route the user is navigating from.
 * @param {Function} next - A callback function to continue the route navigation.
 */
export async function logout(to, from, next) {
  try {
    // Call the refresh endpoint on the backend to log the user out
    const response = await axios.post(
      '/api/auth/logout',
      {},
      {
        authRequired: true,
      },
    )
    const data = response.data

    if (data.success) {
      // Dispatch the 'logout' action to clear user data in the store
      store.dispatch('logout', {})
    }
    // Redirect the user to the login page after successful logout
    return next('/login')
  } catch (error) {
    // Handle any errors that occur during the logout process silently
    return next('/login')
  }
}

/**
 * Change the user's password by sending a request to the backend.
 *
 * @param {string} currentPassword - The current password of the user.
 * @param {string} newPassword - The new password to set.
 * @returns {Promise<Object>} - Promise that resolves to an object with result information.
 */
export async function changePassword(currentPassword, newPassword) {
  try {
    //  Call the backend to change the user's password
    const response = await axios.put(
      '/api/auth/password',
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },

      {
        authRequired: true,
      },
    )

    // Check if the password change was successful or not
    if (response.data.success) {
      // If successful, return an object indicating success
      return { success: true, message: null, result: null }
    } else {
      // If unsuccessful, return an object indicating failure with the error message
      return { success: false, message: response.data.message, result: null }
    }
  } catch (error) {
    // Handle network or other errors during the password change and return an error object

    return {
      success: false,
      message: 'Password change error: ' + error,
      result: null,
    }
  }
}

/**
 * Create an OTP (One-Time Password) seed by making a secure authenticated request to the backend.
 * This function fetches an OTP seed from the backend API using an access token.
 * @returns {Promise<object|null>} An object containing OTP seed information, or null in case of errors.
 */
export async function createOTPSeed() {
  try {
    // Call the endpoint on the backend to retrieve authentication methods
    const response = await axios.post(
      '/api/auth/otp',
      {},
      {
        authRequired: true,
      },
    )

    return response.data
  } catch (error) {
    // Handle any errors that occur during the request silently
    return { success: false, message: error, result: null }
  }
}

/**
 * Verify an OTP (One-Time Password) code by making a secure authenticated request to the backend.
 * This function sends the OTP code to the backend API for verification and registers the OTP seed if successful.
 *
 * @param {string} otpCode - The OTP code to be verified.
 * @returns {Promise<object>} An object containing the verification result.
 */
export async function verifyOTPSeed(otpCode) {
  try {
    // Call the endpoint on the backend to verify the OTP code
    const response = await axios.put(
      '/api/auth/otp',
      {
        otpCode: otpCode,
      },
      {
        authRequired: true,
      },
    )

    // Return the verification result from the response
    return response.data
  } catch (error) {
    // Handle any errors that occur during the request and return an error response
    return { success: false, message: error.message, result: null }
  }
}

export async function fetchOTP() {
  try {
    // Call the endpoint on the backend to get all enabled Authenticators
    const response = await axios.get('/api/auth/otp', {
      authRequired: true,
    })

    // Return the result from the response
    return response.data
  } catch (error) {
    // Handle any errors that occur during the request and return an error response
    return { success: false, message: error.message, result: null }
  }
}
export async function deleteOTP() {
  try {
    // Call the endpoint on the backend to delete all enabled Authenticators
    const response = await axios.delete('/api/auth/otp', {
      authRequired: true,
    })

    // Return the result from the response
    return response.data
  } catch (error) {
    // Handle any errors that occur during the request and return an error response
    return { success: false, message: error.message, result: null }
  }
}
